

















import Vue from 'vue'

export default Vue.extend({
  name: 'Mp3ExtractorUrlForm',
  data () {
    return {
      url: '',
      label: '',
      matrixNo: ''
    }
  },
  mounted() {
    (this.$refs.input as any).focus()
  },
  methods: {
    clearAllSearchTerms () {
      this.url = ''
      this.label = ''
      this.matrixNo = ''
      this.$emit('change', {})
    },
    submitUrl () {
      this.$emit('change', { searchTerm: {
        url: this.url,
        label: this.label,
        matrixNo: this.matrixNo
      } })
      this.url = ''
      //this.label = '' // don't clear label for now
      this.matrixNo = ''
    }
  }
})
