






























import Vue from 'vue'
import { DadAPI } from '@/api'
import Mp3ExtractorUrlForm from '@/components/Mp3ExtractorUrlForm.vue'
// eslint-disable-next-line no-unused-vars
import { DownloadLinksResponse, SongInfo } from '@/types'
import { formatArtistName, sortArtists } from '@/helpers'

export default Vue.extend({
  components: {
    Mp3ExtractorUrlForm
  },
  data () {
    return {
      searchTerm: {
        url: '' as string,
        label: '' as string,
        matrixNo: '' as string
      },
      results: [] as string[],
      songInfo: {} as SongInfo,
      jukeboxId: '' as string,
      announcementMessage: '',
      timeoutId: undefined as undefined | number
    }
  },
  watch: {
    searchTerm(newVal: { url: string; label: string; matrixNo: string; }, oldVal:  { url: string; label: string; matrixNo: string; }) {
      if (newVal && newVal !== oldVal) {
        this.results = []
        this.songInfo = {
          title: [],
          otherTitle: [],
          summary: [],
          names: [],
          genre: [],
          mediaSize: [],
          recordingLabel: [],
          recordingCatalogNumber: [],
          recordingMatrixNumber: [],
          recordingTakeNumber: [],
          recordingDate: [],
          recordingLocation: [],
          recordingRepository: [],
        };

        // trim
        let url = newVal.url.trim()

        // search by URL
        if (url) {
          if (!this.isValidUrl(url)) {
            this.announce("Invalid URL")
            return;
          }

          this.announce("URL is invalid")
          const domain = url.includes('ucsb.edu') ? 'ucsb' : 'loc'

          this.announce(`Searching.`)

          DadAPI
            .extractDownloadLinksByUrl(domain, url)
            .then((response: DownloadLinksResponse): void => {
              
              this.results = response.downloadLinks
              this.songInfo = response.songInfo
              this.jukeboxId = response.jukeboxId

              for (let key in this.songInfo) {
                // Check if the value is null
                if (this.songInfo[key] === null) {
                  // Replace the null value with an empty array
                  this.songInfo[key] = [];
                }
              }
              
              this.announce(`Complete, ${response.downloadLinks.length} MP3 links found.`)
            })
            .catch((e) => {
              console.error(e)
              this.announce(`Something went wrong, try again.`)
            })
            return;
        }

        // search by label and matrix no.
        if (!this.searchTerm.url && this.searchTerm.label && this.searchTerm.matrixNo) {
          this.announce(`Searching.`)

          DadAPI
            .extractDownloadLinksByMatrix(this.searchTerm.label.trim(), this.searchTerm.matrixNo.trim())
            .then((response: DownloadLinksResponse): void => {
              this.results = response.downloadLinks
              this.songInfo = response.songInfo
              this.jukeboxId = response.jukeboxId

              for (let key in this.songInfo) {
                // Check if the value is null
                if (this.songInfo[key] === null) {
                  // Replace the null value with an empty array
                  this.songInfo[key] = [];
                }
              }

              this.announce(`Complete, ${response.downloadLinks.length} MP3 links found.`)
            })
            .catch((e) => {
              console.error(e)
              this.announce(`Something went wrong, try again.`)
            })
            return;
        }
      }
    }
  },
  methods: {
    isValidUrl(string: string) {
      const regex = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(\/[^\s]*)?$/i;
      return regex.test(string);
    },
    announce(msg: string) {
      clearTimeout(this.timeoutId)

      this.announcementMessage = ""
      this.announcementMessage = msg

      this.timeoutId = setTimeout(() => {
        this.announcementMessage = ""
      }, 1000)
    },
    convertDate(dateStr: string) {
        try {
          // Split the input date by the hyphen (-)
          const [year, month, day] = dateStr.split('-');
          
          // Return the date in DD.MM.YYYY format
          return `${day}.${month}.${year}`;
        } catch (e) {
          return dateStr;
        }
    },
    formatArtistName(name: string) {
      return formatArtistName(name) + " "
    },
    sortArtists(names: Array<string>) {
      return sortArtists(names)
    }
  }
})
